const { useEffect } = require('react');

const { datadogRum } = require('@datadog/browser-rum');

const useDataDogRum = (props) => {
  const datadogRumExecute = (datadogRumData = {}) => {
    const {
      userId,
      version,
      service,
      applicationId,
      clientToken,
      env,
      sessionSampleRate,
      sessionReplaySampleRate,
      experiments,
      alreadyRedirected,
    } = datadogRumData;

    if (!applicationId) {
      console.log('cannot start datadog rum');
    }

    try {
      datadogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.com',
        service,
        env,
        version,
        sessionSampleRate,
        sessionReplaySampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.setUser({ name: userId || 'unknown' });
      datadogRum.startSessionReplayRecording();
      datadogRum.setGlobalContextProperty('experiments', experiments || 'not_apply');
      datadogRum.setGlobalContextProperty('alreadyRedirected', alreadyRedirected || 'not_apply');
    } catch (e) {
      console.log('cannot start datadog rum');
    }
  };

  useEffect(() => {
    datadogRumExecute(props);
  }, [props]);
};

module.exports = useDataDogRum;
